import React, { forwardRef, useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import { compose } from "recompose";
import shortid from "shortid";
import clsx from "clsx";

import withStores from "@framework/src/libs/withStores";
import withStyles from "@framework/src/libs/withStyles";

import useStores from "@hooks/useStores";
import useStyles from "@hooks/useStyles";

import TextField from "@material-ui/core/TextField";

import { stores, styles } from "../.config";
import unsupported from "../@libs/unsupported";
import stripHtml from "striptags";

export const BasicTextField = compose(
	withStores(stores),
	withStyles(styles),
	observer,
	forwardRef
)(
	(
		{
			id,
			errorHelperText,
			groupId,
			type,
			value,
			defaultValue,
			onValidation,
			validation,
			error,
			required: isRequired,
			disableErrorText,
			helperText,
			onChange,
			inputRef,
			onBlur,
			InputProps,
			disableOnBlurValidation,
			variant,
			inputProps,
			...props
		},
		ref
	) => {
		const [_id] = useState(id || shortid.generate());
		const { textFieldStore: store } = useStores();
		const classes = useStyles("TextField");

		const instance = store.findInstanceById(_id);
		const _helperText =
			store.showError(_id) && !disableErrorText
				? errorHelperText || store.errorMessage(_id)
				: helperText;

		if (!inputRef) inputRef = useRef();
		const InputPropsClasses = Object.assign({}, InputProps.classes || {});

		Object.assign(
			InputPropsClasses,
			variant === "standard"
				? { root: clsx([InputPropsClasses.root, classes.root]) }
				: {},
			variant !== "standard"
				? {
						adornedStart: clsx([
							InputPropsClasses.adornedStart,
							classes.adornedStart,
						]),
				  }
				: {},
			variant !== "standard"
				? {
						adornedEnd: clsx([
							InputPropsClasses.adornedEnd,
							classes.adornedEnd,
						]),
				  }
				: {}
		);

		useEffect(() => {
			store.init({ type, groupId, id: _id, ref: inputRef, validation });
			if (value || defaultValue) store.isValid(_id);
			return () => store.removeInstanceById(_id);
		}, []);

		const onChangeHandler = (e) => {
			let str = e.target.value || "";
			let endsWithLessThanSign = false;
			if (str.endsWith("<")) {
				endsWithLessThanSign = true;
			}
			let sanitizedValue = stripHtml(`${str}`);
			if (endsWithLessThanSign) sanitizedValue += "<";
			if (!sanitizedValue) {
				onValidation(store.isValid(_id));
				onChange(e);
				return;
			}
			e.target.value = sanitizedValue;
			onValidation(store.isValid(_id));
			onChange(e);
		};

		useEffect(() => {
			if (!instance) return;
			if (error === true && instance.showError === false)
				instance.displayError(true);
			if (error === false && instance.showError === true)
				instance.displayError(false);
			instance.isRequired = isRequired;
		});

		const onBlurHandler = (e) => {
			if (disableOnBlurValidation === false) onValidation(store.isValid(_id));
			onBlur(e);
		};

		return (
			<TextField
				ref={ref}
				inputRef={inputRef}
				onBlur={onBlurHandler}
				error={error || store.showError(_id)}
				helperText={_helperText}
				value={value}
				defaultValue={defaultValue}
				onChange={onChangeHandler}
				id={_id}
				type={type}
				variant={variant}
				inputProps={Object.assign(
					type === "number"
						? {
								inputMode: "numeric",
								autoCorrect: "off",
								spellCheck: "false",
						  }
						: {},
					inputProps
				)}
				InputProps={Object.assign({}, InputProps, {
					classes: InputPropsClasses,
				})}
				{...props}
			/>
		);
	}
);

BasicTextField.defaultProps = {
	"type": "text",
	"onValidation": () => {},
	"onChange": () => {},
	"onBlur": () => {},
	"required": true,
	"aria-label": "Enter a value",
	"placeholder": "",
	"helperText": "",
	"errorHelperText": "",
	"inputProps": {},
	"InputProps": {},
	"style": {},
	"variant": "standard",
	"disableErrorText": false,
	"disableOnBlurValidation": false,
};

BasicTextField.propTypes = {
	type: PropTypes.oneOf(["text", "number"]),
	variant: PropTypes.oneOf(["standard", "filled", "outlined"]),
	creditCardNumberId: unsupported("text"),
	creditCardNumber: unsupported("text"),
	showPassword: unsupported("text"),
	keepCharPositions: unsupported("text"),
	delimiter: unsupported("text"),
	placeholderChar: unsupported("text"),
	guide: unsupported("text"),
	showMask: unsupported("text"),
	disableOnBlurValidation: PropTypes.bool,
	validation: PropTypes.func,
	onBlur: PropTypes.func,
	errorHelperText: PropTypes.string,
	helperText: PropTypes.string,
	useDefaultStartAdornment: unsupported("text"),
	useDefaultEndAdornment: unsupported("text"),
	onValidation: PropTypes.func,
	disableErrorText: PropTypes.bool,
	required: PropTypes.bool,
	onChange: PropTypes.func,
	defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	name: PropTypes.string,
	inputProps: PropTypes.object,
	InputProps: PropTypes.object,
	style: PropTypes.object,
	groupId: PropTypes.string,
	placeholder: PropTypes.string,
	...TextField.propTypes,
};
